import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import {
  analytics,
  TopProgressBar,
  GlobalStyle,
  firebase,
  i18n,
  ChatOptimizationProvider,
} from "@project/shared";
import { useEffect, useRef } from "react";
import "antd/dist/antd.css";
import { useRouter } from "next/router";
import "../utils/css-imports";
import "nprogress/nprogress.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "../utils/globalStyles.css";
import { Layout, ConfigProvider } from "antd";
import styled from "styled-components";
import { AuthProvider } from "../utils/AuthContext";
import Head from "next/head";
import { Sidebar } from "../components/molecules";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import jaJP from "antd/lib/locale/ja_JP";
import enUS from "antd/lib/locale/en_US";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    // enabled: process.env.NODE_ENV !== "development",
    environment: `company-${process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const LayoutWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 100%;
  position: relative;
  background-color: #f0f2f5;
  & .ant-layout-header {
    height: 54px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  & .ant-layout-content {
    padding: 30px;
  }
  & > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export { Layout };

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();
  const myRef = useRef<any>();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      queryClient.setDefaultOptions({
        queries: {
          enabled: user !== null,
        },
      });
    });
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Sidebizz-company",
        });
      };

      routers.events.on("routeChangeComplete", (url) => {
        myRef?.current.scrollIntoView({
          behavior: "smooth",
        });
        logEvent(url);
      });

      logEvent(window.location.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  // useEffect(() => {
  //   firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  // }, []);

  const restrictedRoute = [
    "/login",
    "/",
    "/sign-up",
    "/forgot-password",
    "/forgot-password/done",
  ];
  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>Company | side bizz</title>
      </Head>
      <GlobalStyle />
      <TopProgressBar />
      <ConfigProvider locale={i18n.language === "en-US" ? enUS : jaJP}>
        <AuthProvider>
          <ChatOptimizationProvider>
            <Layout>
              {!restrictedRoute.includes(routers.pathname) &&
                !routers.pathname.startsWith("/job-details") && <Sidebar />}

              <LayoutWrapper>
                <div ref={myRef}>
                  <Component {...pageProps} />
                </div>
              </LayoutWrapper>
            </Layout>
          </ChatOptimizationProvider>
        </AuthProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
