import { DashboardSideNav, firebase } from "@project/shared";
import { Layout } from "antd";
import { useRouter } from "next/router";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AuthContext } from "../../../utils/AuthContext";

const { Sider } = Layout;

const SiderWrapper = styled(Sider)`
  min-width: 256px !important;
  max-width: 256px !important;
  flex: 0 0 256px !important;
`;

const Sidebar = () => {
  const router = useRouter();
  const { user, setUser, claims, unreadMessagesCount } = useContext(
    AuthContext
  );

  const { t } = useTranslation();

  const handleSignOut = async () => {
    await firebase.auth().signOut();
    setUser(null);
    // Sentry.configureScope((scope) => scope.setUser(null));
    router.push("/login");
  };
  const commonMenus = [
    {
      className: router.pathname.startsWith("/jobs") && "active",
      key: "1",
      name: t("Job offer"),
      onClick: () => router.push("/jobs"),
    },
    {
      className: router.pathname.startsWith("/entries") && "active",
      key: "2",
      name: t("Entry"),
      onClick: () => router.push("/entries"),
    },
    {
      className: router.pathname.startsWith("/scouts") && "active",
      key: "3",
      name: t("Scout"),
      onClick: () => router.push("/scouts"),
    },
    {
      className: router.pathname.startsWith("/referrals") && "active",
      key: "4",
      name: t("Referral"),
      onClick: () => router.push("/referrals"),
    },
    {
      className:
        router.pathname.startsWith("/contract-information") && "active",
      key: "5",
      name: t("Contract Information"),
      onClick: () => router.push("/contract-information"),
    },
    {
      className: router.pathname.startsWith("/chat") && "active",
      key: "6",
      name: t("Message"),
      onClick: () => router.push("/chat"),
      unreadCount: unreadMessagesCount,
    },
  ];
  const companyMenus = [
    ...commonMenus,
    {
      className: router.pathname.startsWith("/sb-members") && "active",
      key: "7",
      name: t("Member"),
      onClick: () => router.push("/sb-members"),
    },
    {
      className: router.pathname.startsWith("/analytics") && "active",
      key: "8",
      name: t("Analytics"),
      onClick: () => router.push("/analytics"),
    },
    {
      className: router.pathname.startsWith("/change-email") && "active",
      key: "9",
      name: t("Change email"),
      onClick: () => router.push("/change-email"),
    },
    {
      className: router.pathname.startsWith("/change-password") && "active",
      key: "10",
      name: t("Change password"),
      onClick: () => router.push("/change-password"),
    },
    {
      className: null,
      key: "12",
      onClick: handleSignOut,
      name: t("Logout"),
    },
  ];
  const supplierMenus = [
    ...commonMenus,

    {
      className: router.pathname.startsWith("/change-email") && "active",
      key: "9",
      name: t("Change email"),
      onClick: () => router.push("/change-email"),
    },
    {
      className: router.pathname.startsWith("/change-password") && "active",
      key: "10",
      name: t("Change password"),
      onClick: () => router.push("/change-password"),
    },
    {
      className: null,
      key: "12",
      onClick: handleSignOut,
      name: t("Logout"),
    },
  ];
  if (!user) {
    return <></>;
  }

  return (
    <SiderWrapper trigger={null} collapsed={false}>
      <DashboardSideNav
        menu={claims?.isCompany === true ? companyMenus : supplierMenus}
        href="/jobs"
      />
    </SiderWrapper>
  );
};

export { Sidebar };
